import default_es_nationality_messages from '../es/nationalityMessages'
import default_es_status_messages from '../es/statusMessages'
import default_es_messages from '../es/messages'

export default {
  ...default_es_messages,
  ...default_es_status_messages,
  ...default_es_nationality_messages,
  city: 'Localidad',
  selectGroup: 'Selecciona sector',
  group: 'Sector',
  introducerSellerId: 'Número de cuenta de indicadora',
  startDate: 'Fecha que ingresó',
  phoneMobile: 'Número de teléfono',
}
