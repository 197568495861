import React from 'react'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import { FormattedMessage } from 'react-intl'
import { snakeToCamel } from '../../../helpers/common'

import CustomCard from './Card'
import CustomListItem from './CustomListItem'

const AddressCard = ({ cardTitleId, cardTitleDefaultMessage, addressData }) => (
  <Grid item xs={12} sm={6}>
    <CustomCard
      titleMessageId={cardTitleId}
      titleDefaultMessage={cardTitleDefaultMessage}
    >
      {!addressData ? (
        <CardContent>
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="There is no registered address"
              id="noRegisteredAddress"
            />
          </Typography>
        </CardContent>
      ) : (
        <List dense>
          {addressData.map((address) => (
            address.level && (
              <CustomListItem
                key={address.level}
                fieldId={snakeToCamel(address.level)}
                data={address.value}
              />
            )
          ))}
        </List>
      )}
    </CustomCard>
  </Grid>
)

export default AddressCard
