import React from 'react'
import { FormattedMessage } from 'react-intl'
import PersonalDataCell from '../../components/PersonalDataCell/PersonalDataCell'
import { snakeToCamel } from '../../helpers/common'
import ContactCell from '../../components/ContactCell/ContactCell'

const nameContactWidth = '25%'
const zoneCnCodeWidth = '10%'

export const mobileColumns = [
  {
    dataIndex: 'name',
    labelDefaultMessage: 'Personal Data',
    labelId: 'personalData',
    ordered: true,
    render: (record) => <PersonalDataCell {...record} />,
    width: '60%',
  },
  {
    dataIndex: 'createdRoleAt',
    labelDefaultMessage: 'Register On',
    labelId: 'registerOn',
    ordered: true,
    width: '40%',
  },
]
export const desktopColumns = [
  {
    dataIndex: 'name',
    labelDefaultMessage: 'Personal Data',
    labelId: 'personalData',
    ordered: true,
    render: (record) => <PersonalDataCell {...record} />,
    width: nameContactWidth,
  },
  {
    dataIndex: 'createdRoleAt',
    labelDefaultMessage: 'Register On',
    labelId: 'registerOn',
    ordered: true,
    width: '10%',
  },
  {
    dataIndex: 'candidateId',
    labelDefaultMessage: 'CN Code',
    labelId: 'consultantCode',
    width: zoneCnCodeWidth,
  },
  {
    dataIndex: 'commercialStructure',
    labelDefaultMessage: 'Estructura',
    labelId: 'structure',
    width: zoneCnCodeWidth,
  },
  {
    dataIndex: 'substatus',
    labelDefaultMessage: 'Register Status',
    labelId: 'registerStatus',
    width: '20%',
    render: ({ substatus }) => (substatus ? (
      <FormattedMessage id={snakeToCamel(`sub_status_${substatus}`)} defaultMessage={substatus} />
      ) : (
        ' - '
      )),
  },
  {
    dataIndex: 'contact',
    labelDefaultMessage: 'Contact',
    labelId: 'contact',
    width: nameContactWidth,
    render: (record) => <ContactCell {...record} />,
  },
]
