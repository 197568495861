import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import crypto from 'crypto-js'
import { snakeToCamel } from '../../../helpers/common'
import CustomCard from './Card'
import CustomListItem from './CustomListItem'
import { getGlobalAccountUrl } from '../../../services/config'
import { useTenantConfigs } from '../../../hooks/useTenantConfigs'
import { CountryId } from '../../../models/country-id'

const checkRenderShowCardLogPhone = (updatedAt) => {
  const country = localStorage.getItem('country')?.toLowerCase()
  const isBrazil = country === CountryId.BRAZIL.toLocaleLowerCase()

  return updatedAt && isBrazil
}

const getCustomPhoneListItem = ({ personId = '', phones = [] }) => phones.map((phone) => {
  const { number, type = 'contact', updatedAt = '' } = phone

  return (
    <CustomListItem
      showCardLog={checkRenderShowCardLogPhone(updatedAt)}
      key={type}
      fieldId={`${snakeToCamel(type)}Number`}
      fieldDefaultMessage="Contact Number"
      data={number}
      dataCardLog={{
        dataLog: phone,
        personId,
      }}
    />
  )
})

const ContactCard = ({ userData, personId }) => {
  const { configs } = useTenantConfigs()
  const globalExternalService = getGlobalAccountUrl()
  const fields = [
    {
      fieldId: 'email',
      fieldDefaultMessage: 'E-mail',
      dataIndex: 'email',
    },
  ]

  const handleEditButton = () => {
    const { tenantId, country, company } = configs

    const maintenanceParams = {
      personId,
      tenantId,
      countryId: country.alpha3Code,
      companyId: company.id,
    }

    const token = crypto.AES.encrypt(JSON.stringify(maintenanceParams), process.env.REACT_APP_CONTACT_PASSPHRASE).toString()

    window.location.href = `${globalExternalService.url}/maintenance-contacts?token=${btoa(token)}`
  }

  const showEditButton = () => configs && configs.profile.shouldDisplayContactEditButton && process.env.REACT_APP_SHOW_EDIT_CONTACT_BUTTON === 'true'

  const checkRenderShowCardLogEmail = (email) => {
    if (!email) {
      return false
    }

    const { emailAddress = '', updatedAt = '' } = email
    const country = localStorage.getItem('country')?.toLowerCase()
    const isBrazil = country === CountryId.BRAZIL.toLocaleLowerCase()

    return emailAddress && updatedAt && isBrazil
  }

  const getData = (field, email) => {
    if (field.render) {
      return field.render()
    }

    const emailAddress = email && email.emailAddress && typeof email.emailAddress === 'string' ? email.emailAddress : ''
    return emailAddress
  }

  return (
    <Grid item xs={12} sm={6}>
      <CustomCard
        titleMessageId="personalContact"
        titleDefaultMessage="Personal Contact"
        showEditButton={showEditButton()}
        onEditButtonClick={handleEditButton}
      >
        <List dense>
          {getCustomPhoneListItem(userData)}
          {fields.map((field) => (
            <CustomListItem
              showCardLog={checkRenderShowCardLogEmail(userData[field.dataIndex])}
              key={field.fieldId}
              fieldId={field.fieldId}
              fieldDefaultMessage={field.fieldDefaultMessage}
              data={getData(field, userData[field.dataIndex])}
              dataCardLog={{
                dataLog: userData[field.dataIndex],
                personId: userData.personId,
              }}
            />
          ))}
        </List>
      </CustomCard>
    </Grid>
  )
}

export default ContactCard
