import { getConfigsMFEFirebase } from '../../../helpers/mfeConfisFirebase'
import { CompanyId } from '../../../models/company-id'
import { CountryId } from '../../../models/country-id'
import { TENANT_ID } from '../tenantId'
import { getDefault } from './defaultConfigs'

import { statusOptions } from '../../../models/filterOptions'

export const getNaturaBrazil = () => ({
  ...getDefault(),
  tenantId: TENANT_ID.NATURA_BRAZIL,
  country: {
    alpha2Code: CountryId.BRAZIL,
    alpha3Code: 'BRA',
    language: 'pt',
    shouldHideDetailButton: false,
  },
  company: {
    name: 'NATURA',
    id: CompanyId.NATURA,
    logout: {
      buttonLinkHref: '/login?country=BRA&company=NATURA',
      externalLink: false,
    },
  },
  profile: {
    ...getDefault().profile,
    shouldDisplayContactEditButton: true,
    editContactWithV3: true,
    logoModel: 'b',
  },
  filter: {
    origin: true,
    restart: true,
  },
  showEditButtonDetails: true,
  showChangeGroupButton: true,
  showExpirationTokenNotesCard: true,
  showMyBusinessButton: true,
  showEmailButton: true,
  redirectUrlToLogin: {
    url: async () => getConfigsMFEFirebase('HOSTED_UI_AUTHENTICATION_URL'),
    clientId: async () => getConfigsMFEFirebase('CLIENT_ID_COGNITO'),
  },
  roles: {
    gn: {
      functionId: 17,
      roleId: 2,
    },
    leader: {
      functionId: 4,
      roleId: 1,
    },
    cb: {
      functionId: 1,
      roleId: 1,
    },
  },
  showCancelRegistrationCard: true,
  redirectURLCancelRegistration: '../',
  canChangeGroup: [statusOptions.suggestedForStart, statusOptions.waitingFirstOrder],
  header: {
    hasRegisterButtonLink: true,
    registerButtonLinkHref: `${process.env.REACT_APP_REGISTRATION_FV_V3}`,
    hasLogout: true,
  },
})
